import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { v4 } from 'uuid'
import './Typewriter.css';

type GoogleBox = {
    search: string
}

export const GoogleBox = ({ search }: GoogleBox) => {

    const prevItemRef = useRef<string>();
    const [resultList, setResultList] = useState(undefined);
    const [apiError, setApiError] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        if (search?.length > 0 && prevItemRef.current !== search) {
            prevItemRef.current = search;
            setApiError('');
            setNote('Asking Google ...');
            callGoogle(search);
        }

    }, [search]);

    const callGoogle = (q: string) => {
        if (q === '')
            return;

        const host = process.env.REACT_APP_API_URL;
        const url = host + "/search/google";
        const data = {
            query: q
        };

        axios
            .post(url, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                }
            })
            .then((res) => {

                setApiError('');
                setNote('');

                const data: any = res.data;
                const result: any = JSON.parse(data.items);

                let tmp: string = "";

                result.forEach((element: any) => {
                    console.log(element.title);
                    tmp += element.title;
                });

                setResultList(result.map((x: any) => {
                    return (
                        <div className="Google-Result-Item" key={v4()}>
                            <div className="Google-Result-Title">
                                <a href={x.link} target="_blank">{x.title}</a>
                            </div>
                            <div className="Google-Result-Text"
                                dangerouslySetInnerHTML={{ __html: x.htmlSnippet }}>
                            </div>
                        </div>
                    );
                }));

                console.log(result);
            })
            .catch((e) => {
                setNote('');
                const code = e.response.status;
                if (code === 403)
                    setApiError("Limit exceeded");
                else if (code === 423)
                    setApiError("Slow down please");
                else
                    setApiError("Sorry, something went wrong");
            });
    };

    return (
        <div className="text-container">
            {(apiError !== '' || note !== ''
                ? (apiError !== '' ? <div className="Search-Error">{apiError}</div> : <div className="Search-Note">{note}</div>)
                : <div className="fade-in-text">{resultList}</div>)}
        </div>
    );
}