import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { v4 } from 'uuid'
import './Typewriter.css';

type GptBox = {
  search: string
}

export const GptBox = ({ search }: GptBox) => {

  const prevItemRef = useRef<string>();
  const results = useRef<string[]>(new Array<string>());
  const [resultList, setResultList] = useState(new Array());
  const [apiError, setApiError] = useState('');
  const [note, setNote] = useState('');

  useEffect(() => {
    if (search?.length > 0 && prevItemRef.current !== search) {
      prevItemRef.current = search;

      setApiError('');
      setNote('Asking GPT ...');

      const delaySleep = () => {
        setTimeout(function () {
          callGPT(search);
        }, 250);
      }
      delaySleep();
    }

  }, [search]);

  const callGPT = (q: string) => {
    if (q === '')
      return;

    const host = process.env.REACT_APP_API_URL;
    const url = host + "/search/gpt";
    const data = {
      query: q
    };

    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        }
      })
      .then((res) => {

        setApiError('');
        setNote('');

        const data = res.data;

        if (data?.text != undefined) {
          const text = data.text.replace(/(\\n\\n|\\n)/g, '<br />');

          results.current.splice(0);
          results.current.push(text);

          setResultList(results.current.map((x: string) => {
            return (
              <div className="GPT-Result-Item" key={v4()}>
                <img src="gptIcon.png"></img>
                <div className="typewriter" dangerouslySetInnerHTML={{ __html: x }}></div>
              </div>
            );
          }));
        }
      })
      .catch((e) => {
        setNote('');
        const code = e.response.status;
        if (code === 403)
          setApiError("Limit exceeded");
        else if (code === 423)
          setApiError("Slow down please");
        else
          setApiError("Sorry, something went wrong");
      });
  };

  return (
    <div className="text-container">
      {(apiError !== '' || note !== ''
        ? (apiError !== '' ? <div className="Search-Error">{apiError}</div> : <div className="Search-Note">{note}</div>)
        : <Fragment>{resultList}</Fragment>)}
    </div>
  );
}