import React from 'react';

function LinkedInFollow() {

    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.linkedin.com/in.js';
        script.type = 'text/javascript';
        script.innerHTML = 'lang: en_US';
        document.getElementsByTagName('head')[0].appendChild(script);
        
    }, []);
    return <script type="IN/FollowCompany" data-id="18388406" data-counter="bottom"></script>;
}

export default LinkedInFollow;
