import React, { Fragment, useState } from "react";
import { GptBox } from "./features/GptBox";
import { GoogleBox } from "./features/GoogleBox";
import "./App.css";
import LinkedInFollow from "./features/LinkedInFollow";
import { InlineFollowButtons, InlineShareButtons } from "sharethis-reactjs";
import ReactGA from "react-ga";

ReactGA.initialize("UA-219914744-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [query, setQuery] = useState<string>("");
  const [result, setResult] = useState<string>("");

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredName = event.target.value;
    setQuery(enteredName);
  };

  // This function is triggered when the Search buttion is clicked
  const search = () => {
    setResult(query);
  };

  const handleKey = (e: any) => {
    if (e.key === "Enter") setResult(query);
  };

  return (
    <>
      <div className="App">

        <div className="HeaderLinkPPL">
          Developed by&nbsp;
          <a href="https://pingponglabs.de" target="_blank">
            Ping Pong Labs
          </a>
          <div style={{ paddingTop: 8 }}><LinkedInFollow /></div>
        </div>

        <div className="HeaderLinkTutorial">
          <a href="https://www.linkedin.com/pulse/how-integrate-chatgpt-your-website-pingponglabs/?trackingId=U6%2F5dLmoQgWxESoD%2B%2Fgl4A%3D%3D" target="_blank">
            Integrate ChatGPT yourself
          </a>
        </div>

        <div className="App-header">
          <div id="App-Titles">
            <div id="main-title">
              <span id="App-Title-GPT">ChatGPT</span>
              <span id="App-Title-vs"> vs</span>
              <span id="App-Title-Google"> Google</span>
            </div>
            <div id="strapline">Compare for yourself</div>
            <div className="wrapper">
              <input
                value={query}
                onChange={inputHandler}
                onKeyDown={handleKey}
                placeholder="Ask me anything"
                className="Input"
              />
              <div className="Search-Button-Container">
                <button onClick={search} className="Search-Button">
                  Ask / Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="Main-Container">
          {result?.length > 0 ? (
            <div className="Search-Container">
              <div className="SearchParent">
                <div className="Search1">
                  <GptBox search={result} />
                </div>
                <div className="Search2">
                  <GoogleBox search={result} />
                </div>
              </div>
            </div>
          ) : (
            <Fragment></Fragment>
          )}

          <div className="Linkedin-Container">
            {result === "" ? (
              <Fragment></Fragment>
            ) : (
              <div className="Vote-Container">
                <div>Can AI chat bots like ChatGPT replace traditional search engines?</div>
                <div>
                  <a href="https://www.linkedin.com/feed/update/urn:li:activity:7016036299245502464"
                    target="_blank">Vote on LinkedIn</a>
                </div>
              </div>
            )}
          </div>

          <div className="FooterLinkTutorial">
            <a href="https://www.linkedin.com/pulse/how-integrate-chatgpt-your-website-pingponglabs/?trackingId=U6%2F5dLmoQgWxESoD%2B%2Fgl4A%3D%3D" target="_blank">
              Integrate ChatGPT yourself
            </a>
          </div>

          <div className="FooterLinkPPL">
            <div>
              Developed by&nbsp;
              <a href="https://pingponglabs.de" target="_blank">
                Ping Pong Labs
              </a>
              <div style={{ paddingTop: 8, paddingBottom: 62 }}><LinkedInFollow /></div>
            </div>
          </div>

        </div>
      </div>
      <footer>
        <div
          style={{
            width: "100%",
            height: "60px",
            position: "fixed",
            bottom: "0",
            backgroundColor: "#ffffff",
          }}
        >
          <div style={{ padding: 10 }}>
            <InlineShareButtons
              config={{
                alignment: "center", // alignment of buttons (left, center, right)
                color: "social", // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 16, // font size for the buttons
                labels: "cta", // button labels (cta, counts, null)
                language: "en", // which language to use (see LANGUAGES)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  "linkedin",
                  "facebook",
                  "twitter",
                  "sharethis",
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 4, // the corner radius on each button (INTEGER)
                show_total: true,
                size: 40, // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS
                url: "https://chat.pingponglabs.de/", // (defaults to current url)
                description: "Can AI chat bots like ChatGPT replace traditional search engines?", // (defaults to og:description or twitter:description)
                title: "ChatGPT vs Google", // (defaults to og:title or twitter:title)
                image: "https://chat.pingponglabs.de/ChatGPT-vs-Google.png", // (defaults to og:image or twitter:image)
                username: "PingPong_Labs", // (only for twitter sharing)
                message: "Can AI chat bots like ChatGPT replace traditional search engines?", // (only for whatsapp sharing)
                subject: "Can AI chat bots like ChatGPT replace traditional search engines?", // (only for email sharing)
              }}
            /></div>
        </div>
      </footer>
    </>
  );
}

export default App;
